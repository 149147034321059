import './App.css'
import Header from './components/GlobalHeader'
import Footer from './components/GlobalFooter'
import AboutUs from './components/AboutUs'

import Services from './components/Services'
import Projects from './components/Projects'

function App() {
  return (
    <div className="App">
      <div className='main'>        
        <Header/>
        <Services />
        <AboutUs />
        <Projects />      
        <Footer />
      </div>
    </div>
  );
}

export default App;
