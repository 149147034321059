import React from 'react'
import '../App.css'
import Arms from '../assets/arms-outline.png'

import {  
  TTLogo
} from '../assets'

export default function GlobalHeader() {
  return (
    <div className='global-header'>
      <div className='container-logo'>
        <img src={TTLogo} style={{width: '100%'}}/>        
      </div>      
      <div style={{width: '400px'}}>
        <img src={Arms} style={{width: '100%'}}/>
      </div>
    </div>
  )
}
