import React from 'react'
import '../App.css'

import {  
  AA_MAG,
  AA_MAG2,
  Counter,
  Sensor,
  ETAS,
  HallSense1,
  HallSense2,
  Signal,
  ProjectBoard,
  TTLogo
} from '../assets'

export default function GlobalFooter() {
  return (
    <div className='global-footer'>
      <div className='container-footer-logo'>
        <div className='container-logo-sm'>
          <img src={TTLogo} style={{width: '100%'}}/>
        </div>      
      </div>      
      <div className='container-contact-info'>
        <div className='contact-info'>
          <a href="mailto:inquiries@tinkertech.io?subject=Project%20Inquiry&body=%5BThanks%20so%20much%20for%20contacting%20TinkerTech!%20In%20order%20to%20more%20quickly%20assess%20your%20project%20inquiry%2C%20please%20provide%20us%20with%20as%20much%20information%20as%20possible%20about%20your%20project.%5D%0D%0A%0D%0ACompany%20%2F%20Individual%20Name%3A%0D%0A%0D%0APhone%20Number%3A%0D%0A%0D%0AProject%20Objectives%3A%0D%0A%0D%0A%5BYou%20may%20have%20an%20idea%20of%20a%20specific%20device%20you%20want%20to%20build%2C%20but%20in%20order%20to%20make%20sure%20we're%20getting%20you%20to%20your%20goal%20as%20efficiently%20as%20possible%2C%20describe%20what%20the%20underlying%20goals%20are%20you're%20trying%20to%20achieve.%20Who%20is%20it%20for%3F%20Why%3F%5D%0D%0A%0D%0AProject%20Value%3A%0D%0A%0D%0A%5BWhat%20value%20will%20this%20project%20provide%20and%20to%20whom%3F%20If%20it's%20a%20commercial%20product%2C%20how%20many%20units%2C%20licenses%2C%20or%20subscriptions%20do%20you%20aim%20to%20sell%20in%20your%20first%20year%20and%20at%20what%20price%20point%3F%5D%0D%0A%0D%0ATinkerTech%20Involvement%3A%0D%0A%0D%0A%5BWhat%20part%20of%20your%20project%20do%20you%20need%20help%20with%3F%20It%20could%20be%20just%20a%20small%20bit%20of%20a%20larger%20project%20or%20handling%20the%20entire%20thing.%20We're%20happy%20to%20help%20either%20way.%5D%0D%0A%0D%0ATimeline%3A%0D%0A%0D%0A%5BRoughly%2C%20when%20do%20you%20need%20your%20project%20completed%20or%20various%20milestones%20achieved%3F%5D%0D%0A%0D%0AProject%20budget%3A%0D%0A%0D%0A%5BWhat%20budget%20range%20can%20you%20allocate%20to%20achieving%20your%20goals%3F%20By%20knowing%20your%20budget%20upfront%2C%20we%20can%20more%20easily%20assess%20whether%20the%20scope%20of%20your%20request%20if%20feasible.%20We%20can%20tailor%20our%20solutions%20and%2For%20help%20you%20tune%20your%20project%20scope%20to%20fit%20your%20available%20budget.%5D%0D%0A%0D%0A">inquiries@tinkertech.io</a><br/>
          <a href='tel:734-707-8019'>734-707-8019</a>
        </div>
      </div>
    </div>
  )
}
