import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import AA_MAG from '../assets/aa_mag_lg.jpg'
import AA_MAG2 from '../assets/aa_mag_2_lg.jpg'
import Counter from '../assets/counter_lg.jpg'
import Sensor from '../assets/envirosensor_lg.jpg'
import ETAS from '../assets/etas_md.jpg'
import HallSense1 from '../assets/hall_sense_lg.jpg'
import HallSense2 from '../assets/hall_sense_breakout_lg.jpg'
import ParkingSignal from '../assets/parking_signal_lg.jpg'
import ParkingSignalVid from '../assets/parking.mp4'
import ProjectBoard from '../assets/project_board_lg.jpg'
import TTLogo from '../assets/Tinkertech-LOGO-CLR.png'
import Strobe from '../assets/strobe_lg.jpg'
import ETASVid from '../assets/etas.mp4'
import BLEBridge from '../assets/ble_bridge.jpg'
import Graffik1 from '../assets/graffik1.jpg'
import Graffik2 from '../assets/graffik2.jpg'
import TinkerBot from '../assets/tinkerbot.jpg'
import FLW from '../assets/flw_blue.jpg'
import FLWVid from '../assets/flw.mp4'

export default () => (
    <Carousel showThumbs={false}>
        <div>
          <img alt="" src={AA_MAG} />
          <p className="legend">CANBus Connected 3-Axis Hall Effect Sensor</p>
        </div>
        <div>
          <img alt="" src={AA_MAG2} />
          <p className="legend">CANBus Connected 3-Axis Hall Effect Sensor</p>
        </div>
        <div>
          <img alt="" src={HallSense1} />
          <p className="legend">Hall Effect Sensor Prototype Hand Soldering</p>
        </div>
        <div>
          <img alt="" src={Sensor} />
          <p className="legend">Environmental Sensor and Data Logger</p>
        </div>
        <div>
          <img alt="" src={BLEBridge} />
          <p className="legend">BLE to LoRa Radio Bridge</p>
        </div>
        <div>
            <img alt="" src={ETAS} />
            <p className="legend">Battery Cell Balancer Simulator</p>
        </div>
        <div>          
          <video className='projects-video' controls muted>
            <source src={ETASVid} type='video/mp4'/>
          </video>
          <p className="legend">Battery Cell Balancer Simulator</p>
        </div>
        <div>
          <img alt="" src={Strobe} />
          <p className="legend">Handheld LED Strobe</p>
        </div>
        <div>
          <img alt="" src={FLW} />
          <p className="legend">Laser Cut RBG LED Lamp</p>
        </div>
        <div>          
          <video className='projects-video' controls>
            <source src={FLWVid} type='video/mp4'/>
          </video>
          <p className="legend">Laser Cut RBG LED Lamp</p>
        </div>
        <div>
            <img alt="" src={ParkingSignal} />
            <p className="legend">Parallel Parking Signal Prototype</p>
        </div>
        <div>          
          <video className='projects-video' controls muted>
            <source src={ParkingSignalVid} type='video/mp4'/>
          </video>
          <p className="legend">Parallel Parking Signal Prototype</p>
        </div>
        <div>
          <img alt="" src={TinkerBot} />
          <p className="legend">Educational Robot Kit</p>
        </div>
        <div>
            <img alt="" src={ProjectBoard} />
            <p className="legend">Educational Electronics Project Board</p>
        </div>
        <div>
            <img alt="" src={Graffik1} />
            <p className="legend">Motion Control Path Planning Software</p>
        </div>
        <div>
            <img alt="" src={Graffik2} />
            <p className="legend">Motion Control Path Planning Software</p>
        </div>

    </Carousel>
);