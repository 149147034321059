import React from 'react'
import Accordion from 'react-spring-accordion'


export default function AccordionContent(props) {

  return (
    <Accordion.Item uid={props.uid}>
      
      <Accordion.Heading 
        className='accordion-heading'
        style={props.headingStyle}>
          {props.title}
      </Accordion.Heading>

      <Accordion.Child>
      <div className='accordion-child'>
        <div className='accordion-img-container'>
          <div className='accordion-img'>
            {!props.video && <img src={props.src} className='img'/>}
            {props.video && 
              <video className='img' autoPlay loop muted >
                <source src={props.src} type='video/mp4'/>
              </video>
            }
          </div>
        </div>
        <div className='accordion-text'>
          {props.text}
        </div>          
      </div>
      </Accordion.Child>            
    
    </Accordion.Item>
  )
}
