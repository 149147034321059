import React from 'react'
import SectionBanner from './SectionBanner'
import ProjectCarousel from './ProjectCarousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default function Projects() {
  return (
    <section className='three-col'>      
      <div className='content-col'>
        <SectionBanner text='Past Projects'/>
        <div className='about-text'>
          We've worked with a broad range of clients, including government agencies, museums,
          startup companies, schools, and universities. Project applications have included
          technology simulations for marketing displays, educational kits, distributed LoRa 
          sensor networks, and motion control and path planning software, among others.          
        </div>
        <div className='carousel-container'>
          <div className='carousel'>
            <ProjectCarousel />
          </div>
        </div>
      </div>
    </section>    
    
  )
}
