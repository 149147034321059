import React from 'react'
import AboutCarousel from './AboutCarousel'
import SectionBanner from './SectionBanner'

export default function AboutUs() {
  return (
    <section className='three-col'>
      <div className='content-col'>
        <SectionBanner text='About Us'/>
        <div className='about-text'>
          TinkerTech begin in 2015 as a technical design consultancy, helping clients
          solve their problems with embedded systems and software development. In 2018,
          we expanded our business to include a makerspace and electronics hobby shop
          with the mission of bringing joy of electronics to a greater audience. Sadly, we
          were forced to close our public storefront in 2020 due to COVID-19 restrictions,
          but we've now once again refocused our efforts on developing novel and creative 
          electronic solutions for our business clients.
        </div>
        <div className='carousel-container'>
          <div className='carousel'>
            <AboutCarousel />          
          </div>
        </div>
      </div>
    </section>
  )
}
