import React from 'react'

export default function SectionBanner(props) {

  return (
    <div className='section-banner-outer'>
      <div className='section-banner'>
        <h1>{props.text}</h1>
      </div>
    </div>
  )
}
