import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import Storefront from '../assets/storefront.jpg'
import Storefront2 from '../assets/storefront2.jpg'
import Storefront3 from '../assets/storefront3.jpg'
import LabStation from'../assets/lab_station.jpg'
import Tinkering1 from '../assets/tinkering1.jpg'
import Tinkering2 from '../assets/tinkering2.jpg'
import Tinkering3 from '../assets/tinkering3.jpg'
import Laser from '../assets/laser.PNG'

export default () => (    
    <Carousel showThumbs={false}>
        <div>
          <img alt="" src={Storefront} />
          <p className="legend">The TinkerTech Makerspace</p>
        </div>
        <div>
          <img alt="" src={Storefront2} />          
        </div>
        <div>
          <img alt="" src={Storefront3} />          
        </div>
        <div>
          <img alt="" src={Laser} />
          <p className="legend">TinkerTech's Laser Cutter</p>
        </div>
        <div>
          <img alt="" src={LabStation} />
          <p className="legend">Makerspace Lab Station</p>
        </div>
        <div>
          <img alt="" src={Tinkering1} />
          <p className="legend">Participants at a TinkerTech Hackathon</p>
        </div>
        <div>
          <img alt="" src={Tinkering2} />          
        </div>
        <div>
          <img alt="" src={Tinkering3} />        
        </div>
    </Carousel>
);