import React from 'react'
import SectionBanner from './SectionBanner'

import Accordion from 'react-spring-accordion';
import AccordionContent from './AccordionContent'

import AA_MAG from '../assets/aa_mag_lg.jpg'
import Firmware from '../assets/firmware.jpg'
import Sensing from '../assets/envirosensor_lg.jpg'
import Mechatronics from '../assets/robot_arm_lg.jpg'
import Mechanical from '../assets/flw_lamp.png'
import IoT from '../assets/ble_bridge.jpg'
import Soldering from '../assets/soldering-outline-blue.png'
import Arduino from '../assets/arduino-outline-blue.png'
import PickAndPlace from'../assets/compressed/VID_20190723_141152472.mp4'


const headingStyle = {
  width: '100vw', 
  backgroundColor: 'grey'
}

export default function Services() {

  return (
    <section className='three-col'>
      <div className={'col' + ' ' + 'phone-hide'}>
        <div className='services-img'>
          <img src={Soldering} className='img'/>
        </div>
      </div>
      <div className='content-col'>
      <div className='services-text-outer'>
          <div className='services-text'>
            TinkerTech is a boutique technical design company specializing in product development,
            prototyping, and creating solutions for niche problems and applications.
            If you have an idea for, or need help with a device that moves, blinks, senses, communicates, or 
            processes data, we have the know-how to pull together all the necessary parts to
            build it or fix it.<br/><br/>
            
            With us, there's no need to find separate mechanical, electrical,
            and software designers, plus a project manager to herd all the cats.<br/><br/>

            TinkerTech is based in Ypsilanti, Michigan, but we can and do work with clients located anywhere. Email us about your idea or problem today 
            at <a href="mailto:inquiries@tinkertech.io?subject=Project%20Inquiry&body=%5BThanks%20so%20much%20for%20contacting%20TinkerTech!%20In%20order%20to%20more%20quickly%20assess%20your%20project%20inquiry%2C%20please%20provide%20us%20with%20as%20much%20information%20as%20possible%20about%20your%20project.%5D%0D%0A%0D%0ACompany%20%2F%20Individual%20Name%3A%0D%0A%0D%0APhone%20Number%3A%0D%0A%0D%0AProject%20Objectives%3A%0D%0A%0D%0A%5BYou%20may%20have%20an%20idea%20of%20a%20specific%20device%20you%20want%20to%20build%2C%20but%20in%20order%20to%20make%20sure%20we're%20getting%20you%20to%20your%20goal%20as%20efficiently%20as%20possible%2C%20describe%20what%20the%20underlying%20goals%20are%20you're%20trying%20to%20achieve.%20Who%20is%20it%20for%3F%20Why%3F%5D%0D%0A%0D%0AProject%20Value%3A%0D%0A%0D%0A%5BWhat%20value%20will%20this%20project%20provide%20and%20to%20whom%3F%20If%20it's%20a%20commercial%20product%2C%20how%20many%20units%2C%20licenses%2C%20or%20subscriptions%20do%20you%20aim%20to%20sell%20in%20your%20first%20year%20and%20at%20what%20price%20point%3F%5D%0D%0A%0D%0ATinkerTech%20Involvement%3A%0D%0A%0D%0A%5BWhat%20part%20of%20your%20project%20do%20you%20need%20help%20with%3F%20It%20could%20be%20just%20a%20small%20bit%20of%20a%20larger%20project%20or%20handling%20the%20entire%20thing.%20We're%20happy%20to%20help%20either%20way.%5D%0D%0A%0D%0ATimeline%3A%0D%0A%0D%0A%5BRoughly%2C%20when%20do%20you%20need%20your%20project%20completed%20or%20various%20milestones%20achieved%3F%5D%0D%0A%0D%0AProject%20budget%3A%0D%0A%0D%0A%5BWhat%20budget%20range%20can%20you%20allocate%20to%20achieving%20your%20goals%3F%20By%20knowing%20your%20budget%20upfront%2C%20we%20can%20more%20easily%20assess%20whether%20the%20scope%20of%20your%20request%20if%20feasible.%20We%20can%20tailor%20our%20solutions%20and%2For%20help%20you%20tune%20your%20project%20scope%20to%20fit%20your%20available%20budget.%5D%0D%0A%0D%0A">inquiries@tinkertech.io</a>.
          </div>
        </div>    
        <SectionBanner text='Services'/>        
        <div className='services-container'>


          <div className='.menu-container-outer'>
            <div className='menu-container'>
              <Accordion.Wrapper onChange={openedItems => console.log(openedItems)}>

              <AccordionContent
                uuid='electronics'
                title='Electronics'
                text="TinkerTech can help develop your idea from a paper napkin sketch into a full schematic, PCB layout,
                and assembled prototype. We can also do short production runs with our pick & place machine and reflow soldering oven.
                We're familiar with power, analog, and digital design, so no matter your application, we've got you covered."
                src={PickAndPlace}
                video
              />

              <AccordionContent
                uuid='firmware'
                title='Firmware'
                text='Virtually every embedded electronic device today relies upon some sort of computing power, either in the form of a
                microcontroller or a single board computer. TinkerTech can develop firmware that fits your needs, from bare metal
                to a full fledged embedded Linux system.'
                src={Firmware}
              />

              <AccordionContent
                uuid='sensing'
                title='Sensing'
                text="From load cells to LiDAR, TinkerTech works with a wide range of sensors every day. We've written drivers
                to interact with digital sensors and created calibration algorithms for analog sensors, so no matter what your project
                requires, it'll be collecting data like a champ."
                src={Sensing}
              />

              <AccordionContent
                uuid='iot'
                title='IoT Communications'
                text="So, we've heard the Internet is a thing, but is your thing on the Internet? We can apply WiFi, Bluetooth,
                cellular, LoRa, and ethernet technologies to get your IoT project connected. Once it's connected, we can also
                stand up a database to store the information gathered by your things and write web apps to interact with your data and devices."
                src={IoT}
              />

              <AccordionContent
                uuid='mechatronics'
                title='Mechatronics'
                text="You don't want your device to just sit there, do you? We're familiar with the hardware and control algorithms needed
                to handle brushed and brushless DC motors, stepper motors, servo motors, and a host of other actuators. From drawing robots
                to pneumatic controllers, we'll make it happen."
                src={Mechatronics}
              />

              <AccordionContent
                uuid='enclosure'
                title='Enclosure Design'
                text={
                <>
                Any trained <a href='https://en.wikipedia.org/wiki/Macaque' target='_blank'>macaque</a> with an engineering degree can design 
                an <a href='https://en.wikipedia.org/wiki/IP_Code' target='_blank'>IP67</a> sealed enclosure to keep your electronics safe (and yes, we can too),
                but can they make it beautiful? Although industrial and harsh environment enclosures are necessary for lots of applications, we love it
                when we get to make something unique and aesthetically pleasing too. We can make your enclosure functional and a work of art, whether we're
                designing for 3D printing, injection molding, or laser cutting.
                </>
                }
                src={Mechanical}
              />
              
              </Accordion.Wrapper>
            </div>
          </div>
        </div>
      </div>

      <div className='col'>
        <div className='services-img'>
          <img src={Arduino} className='img'/>
        </div>
      </div>
    </section>
  )
}
